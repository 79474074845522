import * as React from 'react';

export interface IQuantityInputProps {

}

const DISABLE_COMPONENT = true;

const QuantityInput = (props: IQuantityInputProps) => {
    const baseClassName = 'quantity-input';

    const [selectedOption, setSelectedOption] = React.useState('1');

    if (DISABLE_COMPONENT) {
        return null;
    }

    return (
        <div className={baseClassName}>
            <div className={`${baseClassName}__options-container`} role='radiogroup' aria-label='TODO'>
                <label className={`${baseClassName}__option-box`}>
                    <input
                        type='radio'
                        value='1'
                        checked={selectedOption === '1'}
                        onChange={() => setSelectedOption('1')}
                    />
                    <span>F-pak</span>
                </label>
                <label className={`${baseClassName}__option-box`}>
                    <input
                        type='radio'
                        value='2'
                        checked={selectedOption === '2'}
                        onChange={() => setSelectedOption('2')}
                    />
                    <span>D-pak</span>
                </label>
            </div>
        </div>
    );
};

export default QuantityInput;
